import {Injectable} from "@angular/core";
import { MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ConfirmationDialogOptions} from "./confirmation-dialog.model";
import {Observable} from "rxjs";
import {ConfirmationDialogComponent} from "./confirmation-dialog.component";

@Injectable({providedIn: 'root'})
export class ConfirmationDialog {
  constructor(private dialog: MatDialog) {
  }

  open(options: ConfirmationDialogOptions): Observable<boolean> {
    const ref: MatDialogRef<ConfirmationDialogComponent, boolean> = this.dialog.open(ConfirmationDialogComponent, {
      panelClass: 'confirmation-dialog-panel',
      data: options
    });
    return ref.afterClosed();
  }
}
