import {Injectable} from '@angular/core';
import {Logger, LoggerService} from '../../logger.service';
import {DashboardType} from 'src/app/shared/components/dynamic-dashboard/models/dashboards.model';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {switchItemsInArray, findIndexByFieldName, isArraySame} from '../../../operators/array.operator';
import {ClientOrgPreferencesData} from '../../../models/client-storage.model';
import {BehaviorSubject} from 'rxjs';
import {SingleWidget, WidgetSize} from '../../../components/dynamic-dashboard/models/widget.model';
import {DynamicDashboardService} from '../../../components/dynamic-dashboard/services/dynamic-dashboard.service';
import {LoadWidgetsService} from "../../../components/dynamic-dashboard/services/load-widgets.service";

@Injectable({
  providedIn: 'root'
})
export class StoreWidgetPreferencesService {
  actualWidgetsArray: SingleWidget[] = [];
  originalWidgetsArray: SingleWidget[];
  multiRowWidgetArray: Array<SingleWidget[]> = [];
  dynamicDashboardPreferences = {};
  currentDashboard: DashboardType;
  private notifyCurrentActualWidgetsArray: BehaviorSubject<SingleWidget[]> = new BehaviorSubject(null);
  notifyCurrentActualWidgetsArrayObservable$ = this.notifyCurrentActualWidgetsArray.asObservable();
  private notifyUserSelectionChanges: BehaviorSubject<SingleWidget[]> = new BehaviorSubject(null);
  notifyUserSelectionChangesObservable$ = this.notifyUserSelectionChanges.asObservable();

  private logger: Logger; components; columnDefs;

  constructor(private loggerFactory: LoggerService,
              private dynamicDashboardService: DynamicDashboardService,
              private loadWidgetsService: LoadWidgetsService
  ) {
    this.logger = this.loggerFactory.getLogger("StoreWidgetPreferencesService");
  }

  /**
   * Set the relevant values for current dashboard ("constructor-wise" method)
   * The method update three key params:
   * 1. currentDasboard => with the current dashboard type
   * 2. originalWidgetsArray => the full widgets optional array for this current dashaboard
   * 3. actualWidgetsArray => the actual widgets array as it been after users selection (locally or from Firebase)
   */
  setDashboardValues(dashboardType: DashboardType, originalWidgetsArray: SingleWidget[]) {
    this.currentDashboard = dashboardType;
    this.originalWidgetsArray = originalWidgetsArray;
  }


  /**
   * Add widget to multiRowWidgetArray
   */
  addWidgetToArray(newWidget: SingleWidget) {
    if (this.actualWidgetsArray && this.actualWidgetsArray.find(widget => widget.type == newWidget.type) == undefined) {
      this.actualWidgetsArray.push(newWidget);
      this.convertWidgetArrayToMultiRows(this.actualWidgetsArray);
      this.notifyUserSelectionChanges.next(this.actualWidgetsArray);
    }
  }

  /**
   * Set new widget to multiRowWidgetArray
   */
  setWidgetsArray(newWidgetArray: SingleWidget[]) {
    this.actualWidgetsArray = newWidgetArray;
    this.convertWidgetArrayToMultiRows(this.actualWidgetsArray);
    this.notifyUserSelectionChanges.next(this.actualWidgetsArray);
  }

  /**
   * Remove widget from multiRowWidgetArray
   */
  removeWidgetFromArray(newWidget: SingleWidget) {
    if (this.actualWidgetsArray && this.actualWidgetsArray.find(widget => widget.type == newWidget.type)) {
      this.actualWidgetsArray.splice(this.actualWidgetsArray.findIndex(widget => widget.type == newWidget.type), 1);
      this.convertWidgetArrayToMultiRows(this.actualWidgetsArray);
      this.notifyUserSelectionChanges.next(this.actualWidgetsArray);
    }
  }

  /**
   * Take care for widget change width event
   * The method change the actualWidgetsArray, and then re-create multiRowWidgetArray
   * @param size current selected size
   * @param currentWidget The current selected widget
   */
  handleWidthChange(size: WidgetSize, currentWidget: SingleWidget) {
    let widgetInActualArray = this.actualWidgetsArray.find(widget => widget.type == currentWidget.type);
    if (widgetInActualArray !== undefined && widgetInActualArray.size !== size) {
      widgetInActualArray.size = size;
      this.convertWidgetArrayToMultiRows(this.actualWidgetsArray);
      this.notifyUserSelectionChanges.next(this.actualWidgetsArray);
    }
  }

  handleDropEvent(event: CdkDragDrop<string[], string[]>) {
    /**
     * Case: If switch is between element from same container
     */
    if (event.previousContainer === event.container && event.currentIndex !== event.previousIndex) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.regenerateActualArray();
      this.logger.debug("actualWidgetsArray", this.actualWidgetsArray);
      this.convertWidgetArrayToMultiRows(this.actualWidgetsArray);
      this.notifyUserSelectionChanges.next(this.actualWidgetsArray);
    }
    /**
     * Case: If switch is between element from different container
     */
    if (event.previousContainer !== event.container) {
      this.logger.debug("Inside handleDropEvent", event);
      let targetRowIndex = this.dynamicDashboardService.findRowByType((event.container.data[0] as any).type, this.multiRowWidgetArray);
      let originalRowIndex = this.dynamicDashboardService.findRowByType(event.item.data.type, this.multiRowWidgetArray);
      let currentWidget: SingleWidget = event.item.data;
      if (this.dynamicDashboardService.isWidgetsOnSameSize(this.multiRowWidgetArray, targetRowIndex, originalRowIndex, event)) {
        this.multiRowWidgetArray = this.dynamicDashboardService.switchItemsFromTwoRows(this.multiRowWidgetArray, this.multiRowWidgetArray[targetRowIndex][event.previousIndex], this.multiRowWidgetArray[originalRowIndex][event.previousIndex]);
        this.regenerateActualArray();
        this.convertWidgetArrayToMultiRows(this.actualWidgetsArray);
      } else {
        /**
         * Case: if original row has only one element
         * No point to updateRowWidgetArray
         * We can simply switch rows
         */
        if (this.dynamicDashboardService.isExactlyOneRow([currentWidget])) {
          this.multiRowWidgetArray = switchItemsInArray(this.multiRowWidgetArray, targetRowIndex, originalRowIndex)
        }
        /**
         * Case: if original row is not full by one element
         */
        else {
          let newPotentialRow: SingleWidget[] = this.multiRowWidgetArray[targetRowIndex];
          /**
           * Case: If target row is full by one elment only
           */
          if (this.multiRowWidgetArray[targetRowIndex].length == 1 && this.dynamicDashboardService.isExactlyOneRow(this.multiRowWidgetArray[targetRowIndex])) {
            /**
             * Case: If target row is full by one elment only and origin row has one element
             */
            if (this.multiRowWidgetArray[originalRowIndex].length == 1) {
              this.multiRowWidgetArray = switchItemsInArray(this.multiRowWidgetArray, targetRowIndex, originalRowIndex)
            } else {
              /**
               * Case: original row is not full, target row is full by one element
               * Find out where to put the new element by rows index
               * and update RowWidgetArray
               */

              if (originalRowIndex > targetRowIndex) {
                newPotentialRow.push(currentWidget)
              } else {
                newPotentialRow.unshift(currentWidget);
              }
              this.updateRowWidgetArray(originalRowIndex, targetRowIndex, newPotentialRow, currentWidget);
            }
          }

          /**
           * Case: If target has more than one element, and origin row is not full by one element
           * Add currentWidget to newPotentialRow and update RowWidgetArray
           */
          else {
            newPotentialRow.splice(event.previousIndex, 0, currentWidget);
            this.updateRowWidgetArray(originalRowIndex, targetRowIndex, newPotentialRow, currentWidget);

          }

        }
        this.regenerateActualArray();
        this.convertWidgetArrayToMultiRows(this.actualWidgetsArray);
      }
      this.notifyUserSelectionChanges.next(this.actualWidgetsArray);
    }
  }

  /**
   * Update the rowWidgetArray
   * The method remove the current widget from the its current row, and remove the target row entirly
   * @param originalRowIndex The index of the row that the widget arrived from
   * @param targetRowIndex The index of the row that the widget has been dragged into
   * @param newPotentialRow The new potential row (target row). Includes the original widgets
   * of the target row and the new widget
   * @param currentWidget The current dragged widget
   */
  updateRowWidgetArray(originalRowIndex: number, targetRowIndex: number, newPotentialRow: SingleWidget[], currentWidget: SingleWidget) {
    //Delete widget from origin row
    this.multiRowWidgetArray[originalRowIndex].splice(findIndexByFieldName(this.multiRowWidgetArray[originalRowIndex], "type", currentWidget.type), 1);
    //Delete target row from multiRowWidgetArray
    this.multiRowWidgetArray.splice(targetRowIndex, 1);
    this.regenerateMultiRowWidgetArray(targetRowIndex, newPotentialRow);

  }

  /**
   * Recreate the multiRowWidgetArray
   * The method checks wheather the new row is able so "swallow" the new widget,
   * and update multiRowWidgetArray accordingly
   */
  regenerateMultiRowWidgetArray(targetRowIndex: number, newPotentialRow: SingleWidget[]) {
    let inLimitRow: SingleWidget[] = [];
    if (this.dynamicDashboardService.isMoreThanOneRow(newPotentialRow)) {
      for (let i = 0; i < newPotentialRow.length; i++) {
        const widget = newPotentialRow[i];
        inLimitRow.push(widget);
        if (!this.dynamicDashboardService.isLessThanOneRow(inLimitRow)) {
          if (this.dynamicDashboardService.isExactlyOneRow(inLimitRow)) {
            this.multiRowWidgetArray.splice(targetRowIndex + i, 0, inLimitRow);
          }

          if (this.dynamicDashboardService.isMoreThanOneRow(inLimitRow)) {
            inLimitRow.forEach(widget => {
              this.multiRowWidgetArray.splice(targetRowIndex + i, 0, [widget]);
            })
          }
          inLimitRow = [];
        } else if (i == newPotentialRow.length - 1 || (i < (newPotentialRow.length - 1) && this.dynamicDashboardService.isExactlyOneRow([newPotentialRow[i + 1]]))) {
          this.multiRowWidgetArray.splice(targetRowIndex + i, 0, [widget]);
          inLimitRow = [];
        }
      }
    } else {
      this.multiRowWidgetArray.splice(targetRowIndex, 0, newPotentialRow);
    }
    //Delete umpty cells in the array
    this.multiRowWidgetArray.forEach((row, rowIndex) => {
      if (row.length == 0)
        this.multiRowWidgetArray.splice(rowIndex, 1);
    })
  }

  /**
   * Regenerate actual array out from multiRowWidgetArray
   */
  regenerateActualArray() {
    this.actualWidgetsArray = [];
    this.multiRowWidgetArray.forEach(row => {
      row.forEach(widget => this.actualWidgetsArray.push(widget));
    })
  }

  /**
   * Convert flat widget array into nested multiRows widgets array
   * @param flatWidgetArray
   * @param multiRowsWidgetArray
   */
  convertWidgetArrayToMultiRows(flatWidgetArray: SingleWidget[] = [], multiRowsWidgetArray: Array<SingleWidget[]> = []) {
    let widgetSizesSum = 0;
    if (flatWidgetArray && flatWidgetArray.length > 0) {
      for (let i = 0; i < flatWidgetArray.length; i++) {
        const widget = flatWidgetArray[i];
        widgetSizesSum += widget.size + 1;
        /**
         * Case: when there is only one item in the array,
         * Or, when the first item is on full widgth
         */

        if (flatWidgetArray.length == 1 || (widgetSizesSum == 3 && i == 0)) {
          multiRowsWidgetArray.push([widget]);
          if (flatWidgetArray[i + 1]) {
            this.convertWidgetArrayToMultiRows(flatWidgetArray.slice(i + 1), multiRowsWidgetArray)
          }
          break;
        }
        /**
         * Case: if total widgetSizesSum is bigger than 3.
         * If yes, "scan" back all items until current and add them as one row
         */
        if (widgetSizesSum > 3) {
          let singleRow: SingleWidget[] = [];
          for (let j = 0; j < flatWidgetArray.length; j++) {
            const widget = flatWidgetArray[j];
            if (j <= i - 1)
              singleRow.push(widget);
          }
          multiRowsWidgetArray.push(singleRow);
          if (flatWidgetArray[i]) {
            this.convertWidgetArrayToMultiRows(flatWidgetArray.slice(i), multiRowsWidgetArray)
          }
          break;
        }
        /**
         * If at the last item in the array  and widgetSizesSum == 3 -
         * Enter all items as one row
         */
        if (widgetSizesSum <= 3 && i == flatWidgetArray.length - 1) {
          let singleRow: SingleWidget[] = [];
          flatWidgetArray.forEach(widget => singleRow.push(widget));
          multiRowsWidgetArray.push(singleRow);
        }

      }
      ;
    }
    ;
    this.multiRowWidgetArray = multiRowsWidgetArray;
  }

  /**
   * Save current dashboard Preferences for current session
   */
  saveDashboardPreferencesLocally(currentDasboard: DashboardType) {
    let updateWidgetsArray: SingleWidget[] = [];
    this.multiRowWidgetArray.forEach(row => row.forEach(widget => updateWidgetsArray.push(widget)));
    if (this.dynamicDashboardPreferences[currentDasboard])
      this.dynamicDashboardPreferences[currentDasboard]["widgetArray"] = updateWidgetsArray;
    else
      this.dynamicDashboardPreferences[currentDasboard] = {["widgetArray"]: updateWidgetsArray};
    this.logger.debug("Save dynamicDashboardPreferences Locally", this.dynamicDashboardPreferences);
  }

  /**
   * Update Dashboard preferences, based on Firebase saved data
   */
  updateDashboardPreferencesFromClientStorage(preferences: ClientOrgPreferencesData<any>) {
    this.dynamicDashboardPreferences = preferences.data;
    if (this.dynamicDashboardPreferences[this.currentDashboard] && this.dynamicDashboardPreferences[this.currentDashboard]["widgetArray"]) {
      this.actualWidgetsArray = this.dynamicDashboardPreferences[this.currentDashboard]["widgetArray"];
      this.convertWidgetArrayToMultiRows(this.actualWidgetsArray);
      this.notifyCurrentActualWidgetsArray.next(this.actualWidgetsArray);
    }
  }

  /**
   * Update actuall Widgets array by firebase or default array
   */
  updateActualWidgetsArray() {
    let actualWidgetsArray: SingleWidget[];
    if (this.currentDashboradPreferences && this.currentDashboradPreferences["widgetArray"] && this.currentDashboradPreferences["widgetArray"].length > 0) {
      actualWidgetsArray = [...this.currentDashboradPreferences["widgetArray"]]
    } else {
      actualWidgetsArray = this.loadWidgetsService.getCurrentDashboardDefaultWidgetsList(this.originalWidgetsArray, this.currentDashboard);
    }
    if (!isArraySame(actualWidgetsArray, this.actualWidgetsArray, "type")) {
      this.actualWidgetsArray = actualWidgetsArray;
      this.convertWidgetArrayToMultiRows(this.actualWidgetsArray);
      this.notifyCurrentActualWidgetsArray.next(this.actualWidgetsArray);
    }
  }

  emptyWidgetNotifier() {
    this.notifyCurrentActualWidgetsArray.next(null);
  }

  get isFirebasePreferencesIsOn() {
    return this.currentDashboradPreferences &&
      this.currentDashboradPreferences["widgetArray"] &&
      this.currentDashboradPreferences["widgetArray"].length > 0;
  }

  get isFirebasePreferences() {
    return this.dynamicDashboardPreferences && Object.keys(this.dynamicDashboardPreferences).length > 0;
  }

  get currentDashboradPreferences(): SingleWidget[] {
    if (this.dynamicDashboardPreferences[this.currentDashboard])
      return this.dynamicDashboardPreferences[this.currentDashboard];
  }
}
