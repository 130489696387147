import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ConfirmationDialogOptions} from "./confirmation-dialog.model";

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  heading: string;
  text: string;

  constructor(private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data: ConfirmationDialogOptions) {
    this.heading = data.heading;
    this.text = data.text;
  }

  close(confirmed: boolean = false) {
    if (this.dialogRef) {
      this.dialogRef.close(confirmed);
    }
  }
}
