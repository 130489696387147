import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from "@angular/core";
import { Logger } from "loglevel";
import { MarkerLocation, VenueLocation, DeviceLocation } from "../../models/map.model";
import { OrgAddress } from "../../models/organizations.model";
import { LoggerService } from "../../services/logger.service";
import * as mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent   {
  @Input() orgAddress: OrgAddress;
  @Input() withSearch: boolean = false;
  @Input() markersLocations: (MarkerLocation | VenueLocation | DeviceLocation)[];
  @Input() mapContainerCss: string;
  @Output() selectedMarkrId: EventEmitter<any> = new EventEmitter();
  @Output() selectedAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('autocomplete') autocomplete: ElementRef;

  locations: (MarkerLocation | VenueLocation | DeviceLocation)[];
  logger: Logger;
  markerClusterer: any;
  searchText: string;
  isReady: boolean = false;

  private map!: mapboxgl.Map;
  private popup = new mapboxgl.Popup({ closeButton: false });
  private overlay!: HTMLElement;
  locationData!: GeoJSON.FeatureCollection<GeoJSON.Point>;

  constructor(private loggerService: LoggerService,) {
    this.logger = this.loggerService.getLogger("MapComponent");
  }




  /**
   * In order to draw map when data is received and when data is empty,
   * we need to invoke initMap inside to lifecycles methods: OnChanges and AfterviewInit
   */
  ngOnChanges(changes: SimpleChanges) {
    this.logger.debug("changes", changes);
    this.loadMarkerLocations();
  }

  /**
   * Load locations with current devices array (markerlocations)
   */
  loadMarkerLocations() {
    this.locations = [];
    if (this.markersLocations?.length > 0 && this.markersLocations[0].lat && this.markersLocations[0].lng) {
      this.locations = this.markersLocations;
      this.isReady = true;
      this.locationData = this.convertToFeatureCollection(this.locations);
      this.buildMap();
    }
  }

  buildMap(): void {
    if (this.map) {
      this.map.remove();
    }

    this.overlay = document.getElementById('map-overlay')!;
    this.map = new mapboxgl.Map({
      accessToken: environment.accessTokenMapBox,
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v12',
 	    // pitch: 0,
  	  // projection: 'mercator',
      center: [-98, 38.88],
      minZoom: 0,
      zoom: 0.2,
    });

    const bounds = new mapboxgl.LngLatBounds();

    this.locationData.features.forEach((feature) => {
      const { coordinates } = feature.geometry as GeoJSON.Point;
      bounds.extend(new mapboxgl.LngLat(coordinates[0], coordinates[1]));
    });

    this.map.fitBounds(bounds, {
      padding: 20,
      maxZoom: 5,
    });

    this.map.on('load', () => this.onMapLoad());
  }

  private onMapLoad(): void {
    this.addClusterIcons();

    this.map.addSource('locations', {
      type: 'geojson',
      data: this.locationData,
      cluster: true,
      clusterMaxZoom: 15,
      clusterRadius: 100,
      clusterProperties: {
        hasRedLocation: [
          'any',
          ['<', ['get', 'health'], 60],
        ],
        hasYellowLocation: [
          'any',
          ['all', ['>=', ['get', 'health'], 60], ['<', ['get', 'health'], 90]],
        ],
      },
    });

    this.addClusterIcons();

    this.map.addLayer({
      id: 'clusters',
      type: 'symbol',
      source: 'locations',
      filter: ['has', 'point_count'],
      layout: {
        'icon-image': [
          'case',
          ['==', ['get', 'hasRedLocation'], true],
          'cluster-red',
          ['==', ['get', 'hasYellowLocation'], true],
          'cluster-orange',
          'cluster-green'
        ],
        'icon-size': 1,
        'text-field': ['to-string', ['get', 'point_count_abbreviated']],
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12,
        'text-anchor': 'center',
        'text-offset': [0, 0],
      },
      paint: {
        'text-color': 'white'
      }
    });

    this.map.addLayer({
      id: 'location_circle',
      type: 'circle',
      source: 'locations',
      paint: {
        'circle-color': [
          'case',
          ['>=', ['get', 'health'], 90], 'green',
          ['>=', ['get', 'health'], 60], 'orange',
          'red',
        ],
        'circle-opacity': 0.6,
        'circle-radius': 10,
      },
    }, 'clusters');

    this.map.on('click', 'clusters', (e) => this.onClusterClick(e));
    this.map.on('mousemove', 'location_circle', (e) => this.onMouseMove(e));
    this.map.on('mouseleave', 'location_circle', () => this.onMouseLeave());
    this.map.on('click', 'location_circle', (e) => this.onMarkerClick(e));
  }

  private onClusterClick(e: mapboxgl.MapMouseEvent): void {
    if (!e.features || e.features.length === 0) {
      return;
    }

    const feature = e.features[0];
    const geometry = feature.geometry;

    if (!geometry || geometry.type !== 'Point') {
      return;
    }

    const coordinates = (geometry as GeoJSON.Point).coordinates as mapboxgl.LngLatLike;
    const clusterId = feature.properties?.cluster_id;
    const source = this.map.getSource('locations') as mapboxgl.GeoJSONSource;

    if (source && clusterId !== undefined) {
      source.getClusterLeaves(clusterId, 100, 0, (err, leaves) => {
        if (err) {
          return;
        }
      });

      source.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) {
          return;
        }

        this.map.easeTo({
          center: coordinates,
          zoom: zoom,
        });
      });
    }
  }

  private onMouseMove(e: mapboxgl.MapMouseEvent): void {
    this.map.getCanvas().style.cursor = 'pointer';

    if (!e.features || e.features.length === 0) {
      return;
    }

    const feature = e.features[0];

    if (feature.properties && feature.properties.point_count) {
      return;
    }

    const { name, health } = feature.properties!;
    const overlayContent = `
      <p><strong>Name:</strong> ${name}</p>
      <p><strong>Health:</strong> ${health}</p>
    `;
    this.overlay.innerHTML = overlayContent;
    this.overlay.style.display = 'block';
  }

  private onMouseLeave(): void {
    this.map.getCanvas().style.cursor = '';
    this.popup.remove();
    this.overlay.style.display = 'none';
  }

  private onMarkerClick(e: mapboxgl.MapMouseEvent): void {
    if (!e.features || e.features.length === 0) {
      return;
    }

    const feature = e.features[0];

    if (feature.properties && feature.properties.point_count) {
      return;
    }

    if (feature.geometry.type === 'Point') {
      const location = {
        markerEntityId: feature.properties?.markerEntityId,
        name: feature.properties?.name,
        health: feature.properties?.health,
      };

      this.selectedMarkrId.emit(location);
    }
  }

  convertToFeatureCollection(data: any[]): GeoJSON.FeatureCollection<GeoJSON.Point> {
    return {
      type: 'FeatureCollection',
      features: data.map(item => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [item.lng, item.lat],
        },
        properties: {
          markerEntityId: item.markerEntityId,
          name: item.name,
          health: item.health,
        },
      })),
    };
  }

  private addClusterIcons(): void {
    const svgColors = ['red', 'orange', 'green'];
    svgColors.forEach(async (color) => {
      const svgString = this.getGoogleClusterInlineSvg(color);
      const image = new Image();
      image.src = svgString;

      image.onload = async () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = 50;
        canvas.height = 50;
        context?.drawImage(image, 0, 0);
        const imageBitmap = await createImageBitmap(canvas);
		if (!this.map.hasImage(`cluster-${color}`)) {
			this.map.addImage(`cluster-${color}`, imageBitmap, { sdf: false });
		}
      };
    });
  }

  private getGoogleClusterInlineSvg(color: string): string {
    const encoded = window.btoa(
      `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-100 -100 200 200">
        <defs>
          <g id="a" transform="rotate(45)">
            <path d="M0 47A47 47 0 0 0 47 0L62 0A62 62 0 0 1 0 62Z" fill-opacity="0.7"/>
            <path d="M0 67A67 67 0 0 0 67 0L81 0A81 81 0 0 1 0 81Z" fill-opacity="0.5"/>
            <path d="M0 86A86 86 0 0 0 86 0L100 0A100 100 0 0 1 0 100Z" fill-opacity="0.3"/>
          </g>
        </defs>
        <g fill="${color}">
          <circle r="42"/>
          <use xlink:href="#a"/>
          <g transform="rotate(120)"><use xlink:href="#a"/></g>
          <g transform="rotate(240)"><use xlink:href="#a"/></g>
        </g>
      </svg>`
    );

    return 'data:image/svg+xml;base64,' + encoded;
  }
}
