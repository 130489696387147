<ng-container *ngIf="items?.length > 0">
    <div class="horizontal-timeline-main-row">
        <ng-container *ngFor="let item of items">
                <div class="no-side-padding timespans-row">
                      <div><span class="date-label"><b>Start:</b></span><span class="time">{{item.start | date: 'MMM d, y HH:mm'}}</span></div>
                      <div><span class="date-label"><b>End:</b></span><span class="time">{{item.end | date: 'MMM d, y HH:mm'}}</span></div>
                      <div><i class="material-icons dot {{item.status}} status-dot">fa_circle</i></div>
                </div>
        </ng-container>
    </div>
</ng-container>
