import {Injectable} from '@angular/core';
import {DashboardType} from 'src/app/shared/components/dynamic-dashboard/models/dashboards.model';
import {FabricsHealthGridComponent} from '../components/fabrics-health-grid/fabrics-health-grid.component';
import {DevicesHealthGridComponent} from '../components/devices-health-grid/devices-health-grid.component';
import {DeviceSingleChartComponent} from '../components/device-single-chart/device-single-chart.component';
import {
  DeviceSinglePropertiesComponent
} from '../components/device-single-properties/device-single-properties.component';
import {DeviceSingleMapComponent} from '../../../../devices/components/devices-single-components/device-dashboard/device-single-map/device-single-map.component';
import {LegacyDeviceSingleTopologyComponent} from '../components/legacy-device-single-topology/legacy-device-single-topology.component';
import {KpisBarComponent} from '../components/kpis-bar/kpis-bar.component';
import {TenantsHealthChartComponent} from '../components/tenants-health-chart/tenants-health-chart.component';
import {TopDevicesComponent} from '../components/traffic-statistics/top-devices/top-devices.component';
import {TopClientsComponent} from '../components/traffic-statistics/top-clients/top-clients.component';
import {
  VpnDeviationBarComponent
} from '../components/traffic-deviation-bar/components/vpn-deviation-bar/vpn-deviation-bar.component';
import {
  DeviceTrafficDeviationBarComponent
} from '../components/traffic-deviation-bar/components/device-traffic-deviation-bar/device-traffic-deviation-bar.component';
import {
  TopVpnConnectionsComponent
} from '../components/traffic-statistics/top-vpn-connections/top-vpn-connections.component';
import {TopAppsComponent} from '../components/traffic-statistics/top-apps/top-apps.component';
import {LossBarComponent} from '../components/loss-latency-bar/components/loss-bar/loss-bar.component';
import {LatencyBarComponent} from '../components/loss-latency-bar/components/latency-bar/latency-bar.component';
import {
  DeviceClientConnectionComponent
} from '../components/clients-bar/components/device-client-connection/device-client-connection.component';
import {
  TopDeviceClientsConnectionComponent
} from '../components/traffic-statistics/top-device-clients-connection/top-device-clients-connection.component';
import {
  TopDeviceConnectionFailuresComponent
} from '../components/traffic-statistics/top-device-connection-failures/top-device-connection-failures.component';
import {
  DeviceConnectionFailureComponent
} from '../components/clients-bar/components/device-connection-failure/device-connection-failure.component';
import {InsightsAnomaliesComponent} from '../components/insights-anomalies/insights-anomalies.component';
import {SingleWidget, WidgetType} from '../models/widget.model';
import {WidgetItem} from '../models/widget-item.model';
import {
  OrganizationTenantsComponent
} from "../../../../organizations/organization-tenants/organization-tenants.component";
import {TenantVenuesComponent} from "../../../../venues/components/tenant-venues/tenant-venues.component";
import {HierarchyMapComponent} from "../components/hierarchy-map/hierarchy-map.component";
import {
  VenueTopologyWithPropertiesComponent
} from "../components/venue-topology-with-properties/venue-topology-with-properties.component";

@Injectable({
  providedIn: 'root'
})
export class LoadWidgetsService {
  readonly DEFAULT_HEALTH_COMPONENTS = [
  ]
  readonly DEFAULT_HEALTH_WIDGETS = [
  ]

  orgWidgets: WidgetItem[] = [
    new WidgetItem(OrganizationTenantsComponent, new SingleWidget(WidgetType.TENANTS_HEALTH_GRID)),
    new WidgetItem(TenantsHealthChartComponent, new SingleWidget(WidgetType.TENANTS_HEALTH_CHART)),
    new WidgetItem(InsightsAnomaliesComponent, new SingleWidget(WidgetType.INSIGHTS_ANOMALIES)),
    new WidgetItem(HierarchyMapComponent, new SingleWidget(WidgetType.HIERARCHY_MAP))
  ];
  orgHealthWidgets: WidgetItem[] = [
    new WidgetItem(OrganizationTenantsComponent, new SingleWidget(WidgetType.TENANTS_HEALTH_GRID)),
    new WidgetItem(InsightsAnomaliesComponent, new SingleWidget(WidgetType.INSIGHTS_ANOMALIES)),
    new WidgetItem(HierarchyMapComponent, new SingleWidget(WidgetType.HIERARCHY_MAP)),
  ];
  orgTenantsWidgets: WidgetItem[] = [
    new WidgetItem(OrganizationTenantsComponent, new SingleWidget(WidgetType.TENANTS_HEALTH_GRID)),
    new WidgetItem(TenantsHealthChartComponent, new SingleWidget(WidgetType.TENANTS_HEALTH_CHART))
  ];
  tenantWidgets: WidgetItem[] = [
    new WidgetItem(TenantVenuesComponent, new SingleWidget(WidgetType.VENUES_HEALTH_GRID)),
    new WidgetItem(InsightsAnomaliesComponent, new SingleWidget(WidgetType.INSIGHTS_ANOMALIES))
  ];
  tenantHealthWidgets: WidgetItem[] = [
    new WidgetItem(TenantVenuesComponent, new SingleWidget(WidgetType.VENUES_HEALTH_GRID)),
    new WidgetItem(InsightsAnomaliesComponent, new SingleWidget(WidgetType.INSIGHTS_ANOMALIES)),
    // new WidgetItem(kpisSelectorsChartComponent, new SingleWidget(WidgetType.CHART_WITH_SELECTORS)),
    //new WidgetItem(HealthChartComponent, new SingleWidget(WidgetType.HEALTH_CHART)),
    // new WidgetItem(ActionsPieComponent, new SingleWidget(WidgetType.ACTIONS_PIE)),
    // new WidgetItem(OrgHealthGridComponent, new SingleWidget(WidgetType.TENANTS_HEALTH_GRID)),
    //new WidgetItem(TrafficChartComponent, new SingleWidget(WidgetType.TRAFFIC_CHART)),
  ];
  fabricsHealthWidgets: WidgetItem[] = [
    new WidgetItem(InsightsAnomaliesComponent, new SingleWidget(WidgetType.INSIGHTS_ANOMALIES)),
    new WidgetItem(FabricsHealthGridComponent, new SingleWidget(WidgetType.FABRICS_HEALTH_GRID)),
    // new WidgetItem(ActionsPieComponent, new SingleWidget(WidgetType.ACTIONS_PIE)),
    // new WidgetItem(kpisSelectorsChartComponent, new SingleWidget(WidgetType.CHART_WITH_SELECTORS))
  ];
  devicesHealthWidgets: WidgetItem[] = [
    new WidgetItem(InsightsAnomaliesComponent, new SingleWidget(WidgetType.INSIGHTS_ANOMALIES)),
    new WidgetItem(DevicesHealthGridComponent, new SingleWidget(WidgetType.DEVICES_HEALTH_GRID)),
    // new WidgetItem(ActionsPieComponent, new SingleWidget(WidgetType.ACTIONS_PIE)),
    // new WidgetItem(kpisSelectorsChartComponent, new SingleWidget(WidgetType.CHART_WITH_SELECTORS))
  ];
  singleDeviceHealthWidgets: WidgetItem[] = [
    new WidgetItem(KpisBarComponent, new SingleWidget(WidgetType.KPIS_BAR)),
    new WidgetItem(DeviceSinglePropertiesComponent, new SingleWidget(WidgetType.DEVICE_SINGLE_PROPERTIES)),
    new WidgetItem(DeviceSingleChartComponent, new SingleWidget(WidgetType.DEVICE_SINGLE_CHART)),
    new WidgetItem(DeviceSingleMapComponent, new SingleWidget(WidgetType.DEVICE_SINGLE_MAP)),
    new WidgetItem(LegacyDeviceSingleTopologyComponent, new SingleWidget(WidgetType.DEVICE_SINGLE_TOPOLOGY)),
    new WidgetItem(InsightsAnomaliesComponent, new SingleWidget(WidgetType.INSIGHTS_ANOMALIES))
  ];
  singleVenueHealthWidgets: WidgetItem[] = [
    new WidgetItem(KpisBarComponent, new SingleWidget(WidgetType.KPIS_BAR)),
    new WidgetItem(VenueTopologyWithPropertiesComponent, new SingleWidget(WidgetType.VENUE_TOPOLOGY_WITH_PROPERTIES))
  ];
  orgReports: WidgetItem[] = [
    new WidgetItem(DeviceTrafficDeviationBarComponent, new SingleWidget(WidgetType.DEVICE_TRAFFIC_DEVIATION)),
    new WidgetItem(TopDevicesComponent, new SingleWidget(WidgetType.TOP_DEVICES))
  ]
  tenantsReports: WidgetItem[] = [
    new WidgetItem(DeviceTrafficDeviationBarComponent, new SingleWidget(WidgetType.DEVICE_TRAFFIC_DEVIATION)),
    new WidgetItem(TopDevicesComponent, new SingleWidget(WidgetType.TOP_DEVICES)),
    new WidgetItem(VpnDeviationBarComponent, new SingleWidget(WidgetType.VPN_TRAFFIC_DEVIATION)),
    new WidgetItem(TopClientsComponent, new SingleWidget(WidgetType.TOP_CLIENTS)),
    new WidgetItem(TopVpnConnectionsComponent, new SingleWidget(WidgetType.TOP_VPN)),
    new WidgetItem(TopAppsComponent, new SingleWidget(WidgetType.TOP_APPS)),
    new WidgetItem(LossBarComponent, new SingleWidget(WidgetType.LOSS_BAR)),
    new WidgetItem(LatencyBarComponent, new SingleWidget(WidgetType.LATENCY_BAR)),
    new WidgetItem(DeviceClientConnectionComponent, new SingleWidget(WidgetType.DEVICE_CLIENTS_CONNECTION)),
    new WidgetItem(TopDeviceClientsConnectionComponent, new SingleWidget(WidgetType.TOP_DEVICE_CLIENTS)),
    new WidgetItem(TopDeviceConnectionFailuresComponent, new SingleWidget(WidgetType.TOP_DEVICE_BY_CONN_FAILURES)),
    new WidgetItem(DeviceConnectionFailureComponent, new SingleWidget(WidgetType.DEVICE_BY_CONN_FAILURES)),
  ]
  venueReports: WidgetItem[] = [
    new WidgetItem(DeviceTrafficDeviationBarComponent, new SingleWidget(WidgetType.DEVICE_TRAFFIC_DEVIATION)),
    new WidgetItem(TopDevicesComponent, new SingleWidget(WidgetType.TOP_DEVICES)),
    new WidgetItem(VpnDeviationBarComponent, new SingleWidget(WidgetType.VPN_TRAFFIC_DEVIATION)),
    new WidgetItem(TopClientsComponent, new SingleWidget(WidgetType.TOP_CLIENTS)),
    new WidgetItem(TopVpnConnectionsComponent, new SingleWidget(WidgetType.TOP_VPN)),
    new WidgetItem(TopAppsComponent, new SingleWidget(WidgetType.TOP_APPS)),
    new WidgetItem(LossBarComponent, new SingleWidget(WidgetType.LOSS_BAR)),
    new WidgetItem(LatencyBarComponent, new SingleWidget(WidgetType.LATENCY_BAR)),
    new WidgetItem(DeviceClientConnectionComponent, new SingleWidget(WidgetType.DEVICE_CLIENTS_CONNECTION)),
    new WidgetItem(TopDeviceClientsConnectionComponent, new SingleWidget(WidgetType.TOP_DEVICE_CLIENTS)),
    new WidgetItem(TopDeviceConnectionFailuresComponent, new SingleWidget(WidgetType.TOP_DEVICE_BY_CONN_FAILURES)),
    new WidgetItem(DeviceConnectionFailureComponent, new SingleWidget(WidgetType.DEVICE_BY_CONN_FAILURES)),

  ]
  deviceReports: WidgetItem[] = [
    new WidgetItem(DeviceTrafficDeviationBarComponent, new SingleWidget(WidgetType.DEVICE_TRAFFIC_DEVIATION)),
    new WidgetItem(TopDevicesComponent, new SingleWidget(WidgetType.TOP_DEVICES)),
    new WidgetItem(VpnDeviationBarComponent, new SingleWidget(WidgetType.VPN_TRAFFIC_DEVIATION)),
    new WidgetItem(TopClientsComponent, new SingleWidget(WidgetType.TOP_CLIENTS)),
    new WidgetItem(TopVpnConnectionsComponent, new SingleWidget(WidgetType.TOP_VPN)),
    new WidgetItem(TopAppsComponent, new SingleWidget(WidgetType.TOP_APPS)),
    new WidgetItem(LossBarComponent, new SingleWidget(WidgetType.LOSS_BAR)),
    new WidgetItem(LatencyBarComponent, new SingleWidget(WidgetType.LATENCY_BAR)),
    new WidgetItem(DeviceClientConnectionComponent, new SingleWidget(WidgetType.DEVICE_CLIENTS_CONNECTION)),
    new WidgetItem(TopDeviceClientsConnectionComponent, new SingleWidget(WidgetType.TOP_DEVICE_CLIENTS)),
    new WidgetItem(TopDeviceConnectionFailuresComponent, new SingleWidget(WidgetType.TOP_DEVICE_BY_CONN_FAILURES)),
    new WidgetItem(DeviceConnectionFailureComponent, new SingleWidget(WidgetType.DEVICE_BY_CONN_FAILURES)),

  ]

  constructor() {
  }

  /**
   * Return all the widget items for each dashboard type
   */
  getWidgetsItems(currentDashboard: DashboardType): WidgetItem[] {
    switch (currentDashboard) {
      case DashboardType.ORG_DASHBOARD:
        return this.orgWidgets;
      case DashboardType.ORG_HEALTH_DASHBOARD:
        return this.orgHealthWidgets;
      case DashboardType.ORG_TENANTS_DASHBOARD:
        return this.orgTenantsWidgets;
      case DashboardType.TENANT_DASHBOARD:
        return this.tenantWidgets;
      case DashboardType.TENANT_HEALTH_DASHBOARD:
        return this.tenantHealthWidgets;
      case DashboardType.FABRICS_HEALTH_DASHBOARD:
        return this.fabricsHealthWidgets;
      case DashboardType.DEVICES_HEALTH_DASHBOARD:
        return this.devicesHealthWidgets;
      case DashboardType.SINGLE_DEVICE_HEALTH_DASHBOARD:
        return this.singleDeviceHealthWidgets;
      case DashboardType.SINGLE_VENUE_HEALTH_DASHBOARD:
        return this.singleVenueHealthWidgets;
      case DashboardType.ORG_REPORTS:
        return this.orgReports;
      case DashboardType.TENANT_REPORTS:
        return this.tenantsReports;
      case DashboardType.VENUE_REPORTS:
        return this.venueReports;
      case DashboardType.DEVICE_REPORTS:
        return this.deviceReports;
      default:
        break;
    }
  }

  /**
   * Return the data for each widget item
   * Main purpose: Get the full optional widgets list for each dashboard
   */
  getCurrentDashboardFullWidgetsList(currentDashboard: DashboardType): SingleWidget[] {
    let dashboardItems = this.getWidgetsItems(currentDashboard);
    return dashboardItems.map(item => item.data);
  }

  /**
   * Return true if currentDashboard is a Health Dashboard
   * @private
   */
  private isHealthDashboard(currentDashboard: DashboardType) {
    return currentDashboard === DashboardType.ORG_DASHBOARD ||
      currentDashboard === DashboardType.DEVICES_HEALTH_DASHBOARD ||
      currentDashboard === DashboardType.FABRICS_HEALTH_DASHBOARD ||
      currentDashboard === DashboardType.ORG_HEALTH_DASHBOARD ||
      currentDashboard === DashboardType.ORG_TENANTS_DASHBOARD ||
      currentDashboard === DashboardType.TENANT_DASHBOARD ||
      currentDashboard === DashboardType.TENANT_HEALTH_DASHBOARD;
  }

  /**
   * Return only default widgets for current dashboard
   * @private
   */
  getCurrentDashboardDefaultWidgetsList(originalWidgetsArray: SingleWidget[], currentDashboard: DashboardType) {
    if (this.isHealthDashboard(currentDashboard)) {
      return [...originalWidgetsArray].filter((item, index) => {
        const isHealthWidget = this.DEFAULT_HEALTH_WIDGETS.find(defaultWidget => defaultWidget === item.type);
        return isHealthWidget !== undefined;
      })
    }
    return [...originalWidgetsArray];
  }
}
