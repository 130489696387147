import {Component, OnInit, Input, ChangeDetectorRef, ChangeDetectionStrategy, SimpleChanges} from '@angular/core';
import {HoriznotalTimeSpan, HorizontalTimelineMarker} from './models/horizontal-time-span.model';
import {HorizontalTimelineService} from './services/horizontal-timeline.service';
import {Logger, LoggerService} from '../../services/logger.service';
import {NgChanges} from "../../extend-angular-classes/on-changes";

@Component({
  selector: 'app-horizontal-timeline',
  templateUrl: './horizontal-timeline.component.html',
  styleUrls: ['./horizontal-timeline.component.scss'],
})
export class HorizontalTimelineComponent {
  @Input() items: HoriznotalTimeSpan[];
  @Input() marker: HorizontalTimelineMarker;

  private logger: Logger; components; columnDefs;
  timeSpans: HoriznotalTimeSpan[];
  markerTooltipData: string;

  constructor(
    private horizontalTimelineService: HorizontalTimelineService,
    private loggerFactory: LoggerService,
  ) {
    this.logger = this.loggerFactory.getLogger("HorizontalTimelineComponent");
  }
}
