import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { NewOrganization, Organization, OrganizationDetails } from '../../models/organizations.model';
import { TenantsHealth, TenantsHealthSync, TenantSummaryDTO } from '../../models/tenants.model';
import { map, mergeMap, take } from 'rxjs/operators';
import { User, UserDetails, UserRole } from '../../models/users.model';
import { GlobalEntitiesService } from './global-entities.service';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/state/app.state';
import { GridSortFilter } from 'src/app/shared/models/sort-filter/grid-sort-filter.model';
import { EntityType } from '../../models/entity-type.enum';
import { Page } from '../../models/page.model';
import { TenantsService } from './tenants.service';
import { OrganizationType } from '../../models/organizations-type.model';
import { TimeUnit } from "../../models/time.model";
import { VenuesService } from "./venues.service";

function convertDtoToOrganization(dto: any): Organization {
  // Add type to the organization entity
  dto["category"] = dto["type"];
  dto["type"] = EntityType.ORGANIZATION
  return dto
}
@Injectable({
  providedIn: 'root'
})
export class OrganizationsService extends GlobalEntitiesService {
  constructor(private http: HttpClient,
    store: Store<IAppState>,
    private tenantsService: TenantsService,
    private venuesService: VenuesService) {
    super(store)
  }
  fetchOrganizations(): Observable<{
    organizations: OrganizationDetails[];
    defaultOrganizationId: number;
  }> {
    return this.http.get("organizations/").pipe(
      map((organizations: OrganizationDetails[]) => {
        organizations.forEach((org) => convertDtoToOrganization(org))
        return {
          organizations,
          defaultOrganizationId: 0
        }
      })
    );
  }
  getOrganizationsOnly(): Observable<OrganizationDetails[]> {
    return this.http.get<OrganizationDetails[]>("organizations/");
  }


  fetchOrganization(orgId: number): Observable<OrganizationDetails> {
    return this.http.get<OrganizationDetails>(`organizations/${orgId}`);
  }

  fetchOrganizationUsersById(orgId: number): Observable<Array<User>> {
    return this.http.get<Array<User>>(`organizations/${orgId}/users`)
  }
  fetchOrganizationUsers(): Observable<Array<User>> {
    return this.orgId$.pipe(
      take(1),
      mergeMap((orgId) => this.http.get<Array<User>>(`organizations/${orgId}/users`)))
  }

  fetchOrganizationsData(): Observable<Array<OrganizationDetails>> {
    return this.orgId$.pipe(
      take(1),
      mergeMap((orgId) =>
        this.fetchOrganizations()
      ),
      map((orgStruct) => orgStruct.organizations)
    )
  }
  fetchOrganizationsDirectChildren(): Observable<OrganizationDetails[]> {
    return combineLatest(this.orgId$, this.fetchOrganizations()).pipe(
      take(1),
      map(([orgId, orgList]) => {
        return orgList.organizations.filter(org => org.parentOrg == orgId)
      })
    )
  }

  createNewOrg(newOrg: NewOrganization): Observable<OrganizationDetails> {
    return this.http.post<OrganizationDetails>(`organizations/`, newOrg)
    // .pipe(
    //   map((org) => convertDtoToOrganization(org) as OrganizationDetails)
    // );
  }
  updateOrganization(organization: OrganizationDetails) {
    // let { category: type, id, name, parentOrg } = organization;
    return this.http.put(`organizations/`, organization)
  }
  fetchOrgUserRoles(): Observable<Array<UserRole>> {
    return this.http.get<Array<UserRole>>(`organizations/roles`);
  }
  inviteUserToOrganization(orgId: number, userDetails: UserDetails): Observable<any> {
    return this.http.post(`organizations/${orgId}/invite`, userDetails).pipe(take(1))
  }

  fetchOrgTenants(): Observable<TenantSummaryDTO[]> {
    return this.orgId$.pipe(
      mergeMap((orgId) => this.fetchTenantsById(orgId))
    );
  }

  fetchOrgTenantsWithVenues(): Observable<TenantSummaryDTO[]> {
    return this.fetchOrgTenants().pipe(
      mergeMap(tenants => {
        const requests: Observable<TenantSummaryDTO>[] = [];
        tenants.forEach(tenant => requests.push(
          this.venuesService.fetchVenuesSummaryByTenantId(tenant.id).pipe(
            map(venues => {
              tenant.venues = venues;
              return tenant;
            })
          )
        ));
        return forkJoin(requests);
      })
    );
  }

  fetchTenantsById(orgId: number): Observable<TenantSummaryDTO[]> {
    return this.http.get<TenantSummaryDTO[]>(`organizations/${orgId}/tenants`);
  }

  fetchTenantsHealthById(orgId: number, sortAndFilter: GridSortFilter, timeBack: number = 1, timeUnit: TimeUnit = TimeUnit.MONTHS): Observable<Page<TenantsHealthSync>> {
    sortAndFilter.sortBy ||= 'criticalIncidentSeverity';
    return this.http.get<Page<TenantsHealth>>(sortAndFilter.appendToURL(`dashboard/health/organization/${orgId}/tenants-health?timeBack=${timeBack}&timeUnit=${timeUnit}`))
      .pipe(
        mergeMap(healthPage => {
          return healthPage.data.length === 0 ? of(healthPage as Page<TenantsHealthSync>) :
            forkJoin([healthPage.data.map((tenantHealth) => {
              this.tenantsService.getTenantStatus(tenantHealth.id).pipe(
                map((syncStatus: any) => {
                  (tenantHealth as TenantsHealthSync).tenantSyncStatus = syncStatus;
                  return tenantHealth as TenantsHealthSync;
                })
              )
            })]).pipe(map(() => healthPage as Page<TenantsHealthSync>));
        })
      )
  }

  fetchTenantsHealth(sortAndFilter: GridSortFilter, timeBack: number = 1, timeUnit: TimeUnit = TimeUnit.MONTHS): Observable<Page<TenantsHealthSync>> {
    return this.orgId$.pipe(
      take(1),
      mergeMap((orgId) => this.fetchTenantsHealthById(orgId, sortAndFilter, timeBack, timeUnit))
    )
  }

  getHighLevelOrganizationTypes(): Observable<OrganizationType[]> {
    return this.highLevelOrg$.pipe(
      take(1),
      mergeMap((orgAsHierarchy) =>
        this.http.get<OrganizationType[]>(`organization-settings/${orgAsHierarchy[0].id}`)
      )
    )
  }
  getOrganizationsTypesById(orgId: number): Observable<OrganizationType[]> {
    return this.http.get<OrganizationType[]>(`organization-settings/${orgId}`);
  }

  addOrgType(newType: OrganizationType) {
    return this.http.post<OrganizationType>(`organization-settings/`, newType).subscribe();
  }
  updateOrgType(newType: OrganizationType) {
    return this.http.put<OrganizationType>(`organization-settings/`, newType).subscribe();
  }
  deleteOrgType(typeToRemove: OrganizationType) {
    return this.http.delete<OrganizationType>(`organization-settings/${typeToRemove.id}`).subscribe();
  }
}
