<app-netop-dialog>
  <app-netop-dialog-title (close)="close()">{{heading}}</app-netop-dialog-title>
  <app-netop-dialog-content>
    {{text}}
  </app-netop-dialog-content>
  <app-netop-dialog-actions>
    <button mat-raised-button color="primary" (click)="close(true)">
      {{'data.COMMON.CONFIRM' | translate}}
    </button>
    <button mat-raised-button color="accent" (click)="close()">
      {{'data.COMMON.CANCEL' | translate}}
    </button>
  </app-netop-dialog-actions>
</app-netop-dialog>
