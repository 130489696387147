import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { mergeMap, of, Subject, Subscription, take, takeUntil } from 'rxjs';
import { ConfirmationDialog } from 'src/app/shared/modals/confirmation-dialog/confirmation-dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-table-actions-cell-renderer',
  templateUrl: './table-actions-cell-renderer.component.html',
  styleUrls: ['./table-actions-cell-renderer.component.scss']
})
export class TableActionsCellRendererComponent implements ICellRendererAngularComp, OnInit, OnDestroy {
  private rowNode: any;
  params: any;
  context;
  gridApi: any;
  gridColumnApi: any;
  stopEditing: any;
  originalData: any;
  editable: Subscription;
  confirmed: boolean;
  refresh: any;
  destroyed$ = new Subject();

  constructor(private confirmationDialog: ConfirmationDialog) { }

  @HostListener('document:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.onCancel();
    }
  }

  agInit(params: any): void {
    this.params = params;
    this.gridApi = this.params.api;
    this.gridColumnApi = params.columnApi;
    this.context = params.context;
    this.rowNode = this.gridApi.getRowNode(this.params.rowIndex.toString());
  }

  ngOnInit(): void {
    this.editable = of(this.context?.editable)?.pipe(takeUntil(this.destroyed$))
      .subscribe(editable => {
        if (editable) {
          this.onEdit(true);
        }
        else if (this.rowNode?.editable) {
          this.context.editable = false;
          this.onCancel(true);
        }
      });
  }

  onEdit(isEdited: boolean, isSaved?: boolean): void {
    this.onCancel();

    if (this.rowNode) {
      if (isEdited) {
        this.originalData = { ...this.rowNode.data };

        if (!this.context.editable || this.rowNode.rowIndex == 0) {
          this.rowNode.editable = true;
          this.gridApi.startEditingCell({
            rowIndex: this.rowNode.rowIndex,
            colKey: this.params.context.componentParent.columnDefs[0].field,
          });
          this.gridApi.setFocusedCell(this.rowNode.rowIndex, this.params.context.componentParent.columnDefs[0].field);
        }
      }
      else this.onCancel(!isSaved);
    }
  }

  onSave(remove?: boolean) {
    const confirmation$ = this.confirmationDialog.open({ heading: 'Edit Credential?', text: `Are you sure you want to edit this credential?` });

    confirmation$.pipe(take(1), mergeMap(confirmed => {
      if (confirmed) {
        this.confirmed = true;
        this.params.context.rows = this.getFullTableData(remove);
        this.onEdit(false, true);
        return this.params.context.componentParent.editRow(this.rowNode.rowIndex, this.context.editable, this.context.isDeleteRow) || of(null);
      }
      else
        return of(null);
    })).subscribe((rows: any[]) => {
      if (this.confirmed) {
        if (remove) {
          this.gridApi.setDatasource({
            getRows: (params) => {
              params.successCallback(rows, rows.length);
            }
          });

          this.context.isDeleteRow = false;
        }

        this.confirmed = false;
        this.context.editable = false;
        this.onCancel();
      }
    });
  }

  onDelete(): void {
    this.context.isDeleteRow = true;
    this.onSave(true);
  }

  onCancel(reset?: boolean): void {
    if (this.rowNode) {
      this.rowNode.editable = false;
      this.params.api!.stopEditing();

      if (reset) {
        if (!this.context?.editable)
          this.rowNode.setData(this.originalData);
        else
          this.params.onCancelNewRow();
      }
    }
  }

  getFullTableData(remove?: boolean): any[] {
    const rowData: any[] = [];

    this.gridApi.forEachNode((node, index) => {
      if (!remove || index !== this.rowNode.rowIndex)
        rowData.push(node.data);
    });

    return rowData;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  get isEdit(): boolean {
    return this.rowNode?.editable == true;
  }
}
