import { ClientsPreferences } from 'src/app/shared/models/client-storage.model';

export class DynamicDashboardPreferences extends ClientsPreferences<{}>{

}
export enum DashboardType {
    ORG_DASHBOARD = "Org Dashboard",
    ORG_HEALTH_DASHBOARD = "Org Health Dashboard",
    ORG_TENANTS_DASHBOARD = "Org Tenants Health Dashboard",
    TENANT_DASHBOARD = "Tenant Dashboard",
    TENANT_HEALTH_DASHBOARD = "Tenant Health Dashboard",
    FABRICS_HEALTH_DASHBOARD = "Fabrics Health Dashboard",
    DEVICES_HEALTH_DASHBOARD = "Devices Health Dashboard",
    SINGLE_DEVICE_HEALTH_DASHBOARD = "Single Device Health Dashboard",
    SINGLE_VENUE_HEALTH_DASHBOARD = "Single Venue Health Dashboard",
    ORG_REPORTS = "Organization Reports",
    TENANT_REPORTS = "Tenant Reports",
    VENUE_REPORTS = "Venue Reports",
    DEVICE_REPORTS = "Device Reports",
}
